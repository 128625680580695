import React, { useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import List from "./List";

const ClientList = () => {
  const [refresh, setRefresh] = useState(false);

  return (
    <div className="history-wrapper ">
      <div className="flex-container text--md  outer-container">
        <div className="email-div ">
          <p className="heading-color">Email</p>
        </div>
        <div className="icon-div"></div>
        <div className="contact-div">
          <p className="heading-color">Contact</p>
        </div>

        <div className="name-div flex-container ">
          <p className="heading-color name-div-heading">Name</p>
          <span onClick={() => setRefresh(!refresh)}>
            <RefreshIcon color="primary" style={{ cursor: "pointer" }} />
          </span>
        </div>
      </div>
      <List key={refresh} />

      <style jsx>
        {`
          .history-wrapper {
            min-height: 90vh;
            padding: 20px;
          }
          .icon-div {
            width: 4%;
          }
          .email-div {
            width: 35%;
            overflow: hidden;
          }
          .contact-div {
            width: 20%;
          }

          .name-div {
            width: 30%;
            justify-content: space-between;
          }
          .heading-color {
            color: var(--gray-light);
          }
          .outer-container {
            border-bottom: 1px solid var(--border-gray);
            padding-bottom: 10px;
            justify-content: space-between;
          }
          .inner-div {
            color: var(--gray-darkest);
          }

          @media only screen and (max-width: 900px) {
            .email-div {
              width: 70%;
            }
            .contact-div {
              display: none;
            }
            .name-div-heading {
              display: none;
            }

            .name-div {
              width: 20%;
              justify-content: right;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ClientList;
