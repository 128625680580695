import React from "react";
import Button from "@mui/material/Button";
import { getDate, getStatusColor } from "./clientHistory.service";
import { Link, useNavigate } from "react-router-dom";

const HistoryItem = ({ historyItem }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (historyItem.type === 0 && historyItem.status === 1) {
      const url = historyItem.report_url;
      window.open(url);
    } else if (historyItem.type === 1 && historyItem.status === 1) {
      const url = historyItem.certificate_url;
      window.open(url);
    }
  };

  return (
    <div className="flex-container text--sm  outer-container margin-top--md ">
      <div className="icon-div">
        <div
          className="icon flex-container justify-center align-center"
          style={{ backgroundColor: getStatusColor(historyItem.status) }}
        >
          {historyItem.client_email.charAt(0).toUpperCase()}
        </div>
      </div>
      <div className="activity-div inner-div">
        <p className="semi-bold">
          {historyItem.type === 0 ? "Self-Assessment" : "Short Course"}
        </p>
        <p className="semi-bold">{historyItem.name}</p>

        <p className="semi-bold">{historyItem.activityName}</p>
      </div>
      <div className="status-div inner-div">
        <span
          className="semi-bold"
          style={{ color: getStatusColor(historyItem.status) }}
        >
          {/* {historyItem.status} */}
          {historyItem.status === 0
            ? "Initiated"
            : historyItem.status === 1
            ? "Completed"
            : historyItem.status === 2
            ? "Expired"
            : null}
        </span>
        &nbsp;&nbsp;
        <span className="semi-bold">{getDate(historyItem.update_time)}</span>
        <div className="semi-bold">
          {historyItem.key_metrics && historyItem.score ? (
            <div>
              Score : {historyItem.score}
              {historyItem.key_metrics.map((item, index) => {
                return item.key ? (
                  <p key={index}>
                    {item.key}: {item.value}
                  </p>
                ) : null;
              })}
            </div>
          ) : historyItem.key_metrics ? (
            historyItem.key_metrics.map((item, index) => {
              return item.key ? (
                <p key={index}>
                  {item.key}: {item.value}
                </p>
              ) : null;
            })
          ) : null}
          {!historyItem.key_metrics && (historyItem.score || historyItem.score === 0)
            ? `Score : ${historyItem.score}`
            : null}
        </div>
      </div>

      <div
        className={
          (historyItem.status === 2 ? "btn" : null) +
          " " +
          "actions-div" +
          " " +
          "inner-div"
        }
      >
        {(historyItem.type === 1 && historyItem.status === 1) ||
        (historyItem.type === 0 && historyItem.status === 1) ? (
          <Button
            style={{ maxHeight: "50px" }}
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            {historyItem.type === 0 && historyItem.status === 1
              ? "View Report"
              : historyItem.type === 1 && historyItem.status === 1
              ? "View Certificate"
              : null}
          </Button>
        ) : null}
        {historyItem.assignment_url && (
          <Button target="_blank" fullWidth href={historyItem.assignment_url}>
            View assignment
          </Button>
        )}
        {historyItem.report_url && historyItem.type !== 0 ? (
          <Button target="_blank" fullWidth href={historyItem.report_url}>
            View report
          </Button>
        ) : null}
      </div>
      <style jsx>
        {`
          .outer-container {
            border-bottom: 1px solid var(--border-gray);
            padding-bottom: 10px;
            justify-content: space-between;
          }
          .icon-div {
            width: 4%;
          }
          .activity-div {
            width: 25%;
            overflow: hidden;
          }
         
          .status-div {
            width: 35%;
          }
          .actions-div {
            width: 25%;
          }
          .inner-div {
            color: var(--gray-darkest);
          }
          .icon {
            background-color: var(--primary-color);
            color: white;
            fontSize: '24px'
            padding: 10px;
            width:30px;
            height:30px;
            border-radius: 50%;
          }
          .btn{
            visibility:hidden;
          }
          .semi-bold {
            font-weight: 500;
          }
          
          @media only screen and ( max-width : 900px ) {
            .activity-div {
              width: 40% !important;
            }
            .source-div {
              display: none ;
            }
            .status-div {
              display: none;
            }
            .actions-div {
              width: 42% !important;
            }
          }
          
          @media only screen and (max-width: 350px) {
            .icon-div {
              display: none;
            }
            .actions-div {
              width: 54% !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default HistoryItem;
