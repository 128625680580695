import React, { Suspense, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Loader from "./app/shared/components/loader/Loader";
import Login from "./app/login/Login";
import Register from "./app/register/Register";
import Dashboard from "./app/dashboard/Dashboard";
import RouteGuard from "./app/shared/components/RouteGuard";
import { useStoreActions, useStoreState } from "easy-peasy";
import { auth } from "./app/helper/firebase.helper";
import { getIdTokenResult, onAuthStateChanged } from "firebase/auth";

function App() {
  const setUser = useStoreActions((actions) => actions.setUser);
  const user = useStoreState((state) => state.user);
  const isUserAuthenticated = user;

  useEffect(() => {
    onAuthStateChanged(auth, async (usr) => {
      if (usr) {
        getIdTokenResult(usr, true).then((res) => {
          if (res.claims?.userType === 1) {
            setUser({
              ...usr,
              orgId: res.claims.orgId,
              userType: res.claims.userType,
            });
          } else {
            setUser(null);
          }
        });
      } else {
        setUser(usr);
      }
    });
  }, []);

  return (
    <Suspense fallback={<Loader />}>
            {user === false ? (
        <Loader />
      ) : (
        <Routes>
          <Route index element={<Navigate to="login" replace />} />
          <Route
            path="/login/*"
            element={
              <RouteGuard
                isAuthenticated={!isUserAuthenticated}
                redPath="/dashboard"
                component={Login}
              />
            }
          />
          <Route path="/register/*" element={<Register />} />
          <Route
            path="/dashboard/*"
            element={
              <RouteGuard
                isAuthenticated={isUserAuthenticated}
                redPath="/login"
                component={Dashboard}
              />
            }
          />
        </Routes>
      )}
      <Toaster position="bottom-center" />
    </Suspense>
  );
}

export default App;
