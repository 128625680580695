import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchClients } from "./clients.service.js";
import ListItem from "./ListItem.js";
import { Link } from "react-router-dom";
import { useStoreState } from "easy-peasy";

const List = () => {
  const [clients, setClients] = useState(null);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const user = useStoreState((state) => state.user);

  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);

  const fetchData = async () => {
    const {
      data: newData,
      hasMore: newHasMore,
      lastVisible: newLastVisible,
    } = await fetchClients(lastVisible, user.orgId);
    if (!newHasMore) {
      setHasMore(false);
    }
    if (newData.length > 0) {
      const updatedClients = clients ? [...clients, ...newData] : newData;
      setClients(updatedClients);
      setLastVisible(newLastVisible);
    }
  };

  return (
    <>
      <InfiniteScroll
        dataLength={clients ? clients.length : 0}
        next={fetchData}
        hasMore={hasMore}
        loader={<p className="optional-heading margin-top">Loading...</p>}
        endMessage={
          clients && clients.length > 0 ? (
            <p className="optional-heading margin-top">That&apos;s All !</p>
          ) : (
            <p className="optional-heading margin-top">No clients yet !</p>
          )
        }
      >
        {clients &&
          clients.map((data, i) => (
            <Link key={data.id} to={`${data.id}`}>
              <ListItem historyItem={data} />
            </Link>
          ))}
      </InfiniteScroll>

      <style jsx>
        {`
          .optional-heading {
            text-align: center;
            font-size: 18px;
          }
        `}
      </style>
    </>
  );
};

export default List;
