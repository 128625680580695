import { useState } from "react";
import { Button, Divider, InputAdornment, TextField } from "@mui/material";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { auth } from "../../helper/firebase.helper";
import googleIcon from "../../assets/google-icon.svg";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import Loader from "../../shared/components/loader/Loader";
import { Helmet } from "react-helmet";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithPopup,
  GoogleAuthProvider,
  browserPopupRedirectResolver,
  getIdTokenResult,
  signOut,
} from "firebase/auth";
import { registerPractitioner } from "../../helper/api.helper";

const RegisterEmail = ({ setStep }) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [eMail, setEMail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    const re =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEMail = (event) => {
    setEMail(event.target.value.trim());
    if (!event.target.value || validateEmail(event.target.email)) {
      setErrors({
        ...errors,
        emailError: true,
      });
    } else {
      setErrors({
        ...errors,
        emailError: false,
      });
    }
  };

  const handleName = (event) => {
    setName(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        nameError: true,
      });
    } else {
      setErrors({
        ...errors,
        nameError: false,
      });
    }
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        passwordError: true,
      });
    } else {
      setErrors({
        ...errors,
        passwordError: false,
      });
    }
  };

  const submitDetails = async () => {
    if (name && eMail && password) {
      setLoading(true);
      try {
        const userCred = await createUserWithEmailAndPassword(
          auth,
          eMail,
          password
        );
        if (userCred) {
          const user = userCred.user;
          registerPractitioner({uid: user.uid, name}).then(res => res.json()).then(async (res) => {
            if (res.status === 'success') {
              await sendEmailVerification(user, {
                url: `${window.location.origin}/register/register-number`,
              });
              sessionStorage.setItem(eMail, password);
              toast.success("Verification email sent!");
              await user.reload();
              if (!user.emailVerified) {
                navigate("/register/verify-email");
              } else {
                navigate("/register/register-number");
              }
            } else {
              setLoading(false);
              toast.error(res.message);
              signOut(auth);
            }
          }).catch((err) => {
            toast.error(err.message);
            signOut(auth);
            setLoading(false);
          })
        } else {
          throw new Error("Failed to create user");
        }
      } catch (error) {
        setLoading(false);
        toast.error(`Failed to register: ${error.message}`);
      }
    } else if (!name) {
      setErrors({
        ...errors,
        nameError: true,
      });
    } else if (!eMail || validateEmail(eMail)) {
      setErrors({
        ...errors,
        emailError: true,
      });
    } else if (!password) {
      setErrors({
        ...errors,
        passwordError: true,
      });
    }
  };

  const validateUser = async (user) => {
    await getIdTokenResult(user, true).then((idTokenResult) => {
      if (!idTokenResult.claims.email) {
        toast.error(
          `Email is missing in the account selected. Please try with other account`
        );
        auth.signOut();
        setLoading(false);
        return;
      }
      setLoading(false);
    });
  };

  const signUpGmail = () => {
    const googleProvider = new GoogleAuthProvider();
    googleProvider.setCustomParameters({
      prompt: "select_account",
    });
    signInWithPopup(auth, googleProvider, browserPopupRedirectResolver)
      .then(async (userRecord) => {
        if (userRecord.user) {
          validateUser(userRecord.user);
          registerPractitioner({uid: userRecord.user.uid, name: userRecord.user.displayName}).then(res => res.json()).then(async (res) => {
            if (res.status === 'success') {
              await auth.currentUser.reload();
              if (!userRecord.user.emailVerified) {
                navigate("/register/verify-email");
              } else {
                navigate("/register/register-number");
              }
            } else {
              setLoading(false);
              toast.error(res.message);
              signOut(auth);
            }
          }).catch((err) => {
            setLoading(false);
            toast.error(err.message);
            signOut(auth);
          })
        }
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
        toast.error(`Failed to login: ${err.message}`);
      });
  };

  return (
    <div className="wrapper ">
      <Helmet>
        <title>Sign up - DeTalks</title>
      </Helmet>
      {loading ? <Loader /> : null}

      <div className="fields-wrapper margin-top--lg">
        <Button
          startIcon={
            <img
              style={{ height: "20px", width: "20px" }}
              className="logo"
              src={googleIcon}
              alt="Google"
            />
          }
          className="gmail-login-button"
          variant="contained"
          fullWidth
          onClick={signUpGmail}
        >
          Sign up with Google
        </Button>
        <Divider sx={{ mt: "20px" }}>
          <p className="text--sm">or continue with</p>
        </Divider>
        <TextField
          required
          size="small"
          fullWidth
          sx={{ marginTop: "15px" }}
          type="name"
          id="name"
          placeholder="Eva Gupta"
          onChange={(e) => handleName(e)}
          label={<span className="text--sm">Name</span>}
          variant="outlined"
          error={errors.nameError}
          helperText={errors.nameError ? "please fill the name" : ""}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonOutlineOutlinedIcon color="primary" />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          required
          size="small"
          fullWidth
          sx={{ marginTop: "15px" }}
          type="email"
          id="email"
          onChange={(e) => handleEMail(e)}
          label={<span className="text--sm">Email</span>}
          placeholder="Enter your email address"
          variant="outlined"
          error={errors.emailError}
          helperText={
            errors.emailError ? "please fill the email in correct format" : ""
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailOutlinedIcon color="primary" />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          required
          fullWidth
          sx={{ marginTop: "15px" }}
          size="small"
          id="password"
          onChange={(e) => handlePassword(e)}
          type="password"
          label={<span className="text--sm">Password</span>}
          variant="outlined"
          error={errors.passwordError}
          placeholder="Enter your password"
          helperText={errors.passwordError ? "Please enter password" : ""}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PasswordOutlinedIcon color="primary" />
              </InputAdornment>
            ),
          }}
        />
        <div style={{ textAlign: "center" }}>
          <Button
            sx={{
              marginTop: "15px",
              fontSize: "var(--text-sm)",
              minWidth: "150px",
            }}
            size="large"
            variant="contained"
            onClick={() => submitDetails()}
            color="primary"
          >
            Submit
          </Button>
        </div>
      </div>

      <style>{`
        .gmail-login-button {
          background-color: white;
          color: black;
          text-transform: none;
          padding: 10px;
          margin-top: 15px;
        }
      `}</style>
      <style jsx>{`
        h2 {
          font-weight: normal;
        }
        .wrapper {
          text-align: center;
          max-width: 800px;
          margin: auto;
          min-height: 91vh;
          padding: 20px;
          margin-top: 50px;
        }
        .fields-wrapper {
          max-width: 500px;
          margin: auto;
          margin-top: 40px;
          padding: 20px;
        }
      `}</style>
    </div>
  );
};

export default RegisterEmail;
