import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";
import RegisterNumber from "./registerNumber/RegisterNumber";
import RegisterEmail from "./registerEmail/RegisterEmail";
import VerifyEmail from "./verifyEmail/VerifyEmail";
import TopNav from "../../app/shared/components/topNav/TopNav";
import Footer from "../shared/components/Footer";

const Register = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#0884ff",
      },
      secondary: {
        main: "#73768c",
      },
    },
    typography: {
      button: {
        textTransform: "capitalize",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <TopNav />
      <Routes>
        <Route path="/" element={<RegisterEmail />} />
        <Route path="/register-user" element={<RegisterEmail />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/register-number" element={<RegisterNumber />} />
      </Routes>
      <Footer />
    </ThemeProvider>
  );
};

export default Register;
