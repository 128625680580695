import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import toast from 'react-hot-toast';
import { useStoreState } from "easy-peasy";

import { database } from '../../../helper/firebase.helper';

const ClientProfile = ({clientId}) => {
  const [clientData, setClientData] = useState();
  const [clientFields, setClientFields] = useState();
  const [orgCustomFields, setOrgCustomFields] = useState();
  const user = useStoreState((state) => state.user);

  useEffect(() => {
    getDoc(doc(database, 'clients', clientId)).then((res) => {
      setClientData({id: res.id, ...res.data()})
    }).catch((err) => {
      toast.error(`failed to get client profile: ${err.message}`)
    })
  }, [clientId]);

  useEffect(() => {
    getDoc(doc(database, 'clients', clientId, 'details', 'custom_fields')).then((res) => {
      setClientFields(res.data())
    }).catch((err) => {
      toast.error(`failed to get client data: ${err.message}`)
    })
  }, [clientId])

  useEffect(() => {
    if (user) {
      getDoc(doc(database, 'organisations', user.orgId, 'details', 'custom_fields')).then((res) => {
        setOrgCustomFields(res.data())
      }).catch((err) => {
        toast.error(`failed to get org data: ${err.message}`)
      })
    }
  }, [user])

  return (
    <div className='client-profile margin-bottom--md'>
      <h4 className='margin-top--sm margin-bottom--xs '>{clientData?.name} - Profile</h4>
      <hr></hr>
      <div className='fields-row flex-container'>
        <div className="field-item margin-bottom--md">
          <p>Email</p>
          <p className='field-data'>{clientData?.email}</p>
        </div>
        {clientFields && orgCustomFields ? orgCustomFields?.fields.map((field) => (
          <div className="field-item margin-bottom--md">
            <p className=''>{field.name}</p>
            <p className='field-data'>{field.type === 3 && clientFields[field.id] ? new Date(clientFields[field.id]).toDateString() : clientFields[field.id] || `NA`}</p>
          </div>
        )): null}

      </div>
      <style jsx>{`
          .client-profile .field-item {
            flex-basis: 32%;
          }
          .client-profile .field-data {
            color: black;
          }
          .client-profile .fields-row {
            flex-wrap: wrap;
            justify-content: space-between;
          }
          @media only screen and (max-width: 900px) {
            .client-profile .field-item {
            flex-basis: 90%;
          }
          }
        `}</style>
    </div>
  );
};

export default ClientProfile;