import { ThemeProvider, createTheme } from "@mui/material/styles";
import ForgotPassword from "./forgotPassword/ForgotPassword";
import { Route, Routes } from "react-router-dom";
import LoginMethods from "./loginMethods/LoginMethods";
import TopNav from "../shared/components/topNav/TopNav";
import Footer from "../shared/components/Footer";

const Login = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#0884ff",
      },
      secondary: {
        main: "#73768c",
      },
    },
    typography: {
      button: {
        textTransform: "capitalize",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <TopNav />
      <Routes>
        <Route index element={<LoginMethods />} />
        <Route path="/forgot-password/*" element={<ForgotPassword />} />
      </Routes>
      <Footer />
    </ThemeProvider>
  );
};

export default Login;
