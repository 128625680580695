import {
  query,
  orderBy,
  collection,
  where,
  limit,
  getDocs,
  startAfter,
} from "firebase/firestore";

import { database } from "../../../helper/firebase.helper";

const PAGE_SIZE = 20;

export const fetchClients = async (lastVisible, orgId) => {
  const clientsRef = collection(database, "clients");
  let q = query(
    clientsRef,
    where("organisation", "==", orgId),
    orderBy("register_time", "desc"),
    limit(PAGE_SIZE)
  );

  if (lastVisible) {
    q = query(q, startAfter(lastVisible));
  }

  const querySnapshot = await getDocs(q);

  let dataArray = [];
  querySnapshot.forEach((doc) => {
    dataArray.push({ id: doc.id, ...doc.data() });
  });

  if (dataArray.length < PAGE_SIZE) {
    // If the fetched data is less than the page size, it means there are no more pages
    return {
      data: dataArray,
      hasMore: false,
      lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1],
    };
  }

  return {
    data: dataArray,
    hasMore: true,
    lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1],
  };
};
