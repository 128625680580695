import React, { useRef } from "react";
import { ClickAwayListener, MenuItem } from "@mui/material";
import Logo from "../logocontainers/logocontainer";
import Popper from "@mui/material/Popper";
import { Hidden, IconButton, Grow, MenuList, Paper } from "@mui/material";
import style from "./Topnav.module.scss";
import { auth } from "../../../helper/firebase.helper";
import { KeyboardArrowDown } from "@mui/icons-material";
import { signOut } from "firebase/auth";
import Account from "../account/Account";

const TopNav = ({ showUser, handleSidebar }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  return (
    <div className={style.navWrapper}>
      <Logo handleSidebar={handleSidebar} />
      {showUser && (
        <div className={style.dropdown}>
          <Hidden smDown implementation="css">
            <Account {...{ showUser }} />
          </Hidden>
          <IconButton onClick={handleToggle}>
            <KeyboardArrowDown ref={anchorRef} />
          </IconButton>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role="button"
            transition
            disablePortal
            placement="bottom-end"
            style={{
              minWidth: "150px",
            }}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: "center top" }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem onClick={() => signOut(auth)}>Logout</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      )}
    </div>
  );
};

export default TopNav;
