import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import ClientList from "./clientList/ClientList";
import ClientHistory from "./clientHistory/ClientHistory";

const Clients = () => {
  return (
    <>
      <Helmet>
        <title>Clients - Detalks</title>
      </Helmet>
      <div style={{ padding: "var(--space-unit)" }}>
        <Routes>
          {/* <Route index element={<Navigate to="" replace />} /> */}

          <Route path="/" element={<ClientList />} />
          <Route path="/:id/*" element={<ClientHistory />} />
        </Routes>
      </div>
    </>
  );
};

export default Clients;
