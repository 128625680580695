import React from "react";
import { IconButton } from "@mui/material";
//import {MenuItem} from '@mui/material/';
//import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import detalkslogo from "../../../assets/detalkslogonew.png";
import styles from "./logocontainer.module.scss";
import { ArrowBack, Menu } from "@mui/icons-material";
import { useMatch, useNavigate } from "react-router-dom";

export default function Logo({ handleSidebar }) {
  const dashboard = useMatch("/dashboard/*");
  const client = useMatch("/dashboard/clients/:id");

  const navigate = useNavigate();

  return (
    <div className={styles.logoContainer}>
      {dashboard ? (
        client ? (
          <div className={styles.actionButton}>
            <IconButton
              color="inherit"
              aria-label="back"
              edge="start"
              onClick={() => {
                if (client) navigate("/dashboard/clients");
              }}
            >
              <ArrowBack />
            </IconButton>
          </div>
        ) : (
          <div className={styles.actionButton}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleSidebar}
            >
              <Menu />
            </IconButton>
          </div>
        )
      ) : null}
      <div className={styles.logo}>
        <div className={styles.pracsite}>
          <img src={detalkslogo} alt="DeTalksLogo" />
        </div>
      </div>
    </div>
  );
}
