import React from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

const ListItem = ({ historyItem }) => {
  return (
    <div className="flex-container text--sm  outer-container">
      <div className="icon-div flex-container justify-center align-center">
        <div className="icon flex-container justify-center align-center">
          {historyItem.email?.charAt(0).toUpperCase()}
        </div>
      </div>
      <div className="email-div inner-div flex-container align-center">
        <p>{historyItem.email}</p>
      </div>
      <div className="contact-div inner-div flex-container align-center">
        <span>{historyItem.mobile}</span>
      </div>

      <div className="name-div inner-div flex-container flex-container align-center">
        <p className="name ">{historyItem.name}</p>
        <IconButton>
          <ArrowForward />
        </IconButton>
      </div>
      <style jsx>
        {`
          .outer-container {
            border-bottom: 1px solid var(--border-gray);
            padding: 10px 0px 10px 0px;
            justify-content: space-between;
          }
            .outer-container:hover{
            background-color: #eee;}
          .icon-div {
            width: 5%;
          }
          .email-div {
            width: 35%;
            overflow: hidden;
          }
         
          .contact-div {
            width: 20%;
          }
          .name-div {
            width: 30%;
            justify-content:space-between;
          }
          .inner-div {
            color: var(--gray-darkest);
          }
          .icon {
            background-color: var(--primary-color);
            color: white;
            fontSize: '24px'
            padding: 10px;
            width:30px;
            height:30px;
            border-radius: 50%;
          }
          
          @media only screen and ( max-width : 900px ) {
           .icon-div {
            width: 10%;
          }
            .email-div {
              width: 60% !important;
            }
            .source-div {
              display: none ;
            }
            .contact-div {
              display: none;
            }
              .name{
               display: none;
              }
            .name-div {
              width: 20% !important;
               justify-content:right;
            }
          }
          
          @media only screen and (max-width: 350px) {
            .icon-div {
              display: none;
            }
            
          }
        `}
      </style>
    </div>
  );
};

export default ListItem;
